milanoAbitareAppControllers.controller('AbbinamentoController',
    ['$scope', '$http', '$location', function ($scope, $http, $location) {

        $scope.abbinamento = [];
        $scope.loading = false;

        $scope.init = function () {
            $scope.loading = true;

        };

        $scope.valutazioneAlloggio = function () {
            $scope.loading = true;
            $http.post('/alloggi/' + $scope.abbinamento.alloggio + '/valutazione/' + $scope.abbinamento.inquilino, {})
                .success(function (data, status, headers, config) {
                $scope.loading = true;
                location.reload();
            });
        };

        $scope.sbloccaAlloggio = function (uuidInquilino) {
            $scope.loading = true;
            $http.post('/alloggi/' + $scope.abbinamento.alloggio + '/sblocca/' + uuidInquilino, {})
                .success(function (data, status, headers, config) {
                location.reload();
            });
        }

        $scope.sbloccaInquilino = function (uuidAlloggio) {
            $scope.loading = true;
            $http.post('/alloggi/' + uuidAlloggio + '/sblocca/' + $scope.abbinamento.inquilino, {})
                .success(function (data, status, headers, config) {
                location.reload();
            });
        }
    }]);