
milanoAbitareAppControllers.controller('NoteController',
    ['$scope', '$http', '$uibModal', function ($scope, $http, $uibModal, $location, $locationProvider) {
        //$locationProvider.html5Mode(true);
        $scope.note = [];
        $scope.loading = false;

        $scope.getNote = function () {
            $scope.loading = true;
            $http.get(window.location.pathname + '/note').
                success(function (data, status, headers, config) {
                    $scope.note = data;
                    $scope.loading = false;
                });
        }

        $scope.init = function () {
            $scope.loading = true;
            $scope.getNote();
        }


        $scope.nuovaNota = function (inquilino) {
            $scope.uuid = {
                uuid: 'undefined'
            };
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: window.location.pathname + '/note/create',
                controller: 'ModalInstanceCtrl',
                size: 'lg',
                resolve: {
                    scopeOriginale: $scope,
                    uuidNota: $scope.uuid
                }
            });
        };

        $scope.modificaNota = function (uuid) {
            $scope.uuid = {
                uuid: uuid
            };

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/note/' + uuid + '/edit',
                controller: 'ModalInstanceCtrl',
                size: 'lg',
                resolve: {
                    scopeOriginale: $scope,
                    uuidNota: $scope.uuid
                }
            });
        };


        $scope.cancellaNota = function (uuid) {
            $scope.uuid = {
                uuid: uuid
            };

            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/note/' + uuid + '/delete',
                controller: 'ModalInstanceCtrl',
                size: 'lg',
                resolve: {
                    scopeOriginale: $scope,
                    uuidNota: $scope.uuid
                }
            });
        };

        $scope.$on('notaAggiunta', function (event, data) {
            //? stato aggiunto un fornitore, refresho la pratica
            //console.log("evento catturato: nota aggiunta");
            $scope.getNote();
        });

        $scope.init();
    }]);

milanoAbitareAppControllers.controller(
    'ModalInstanceCtrl', [
        '$scope',
        '$http',
        '$uibModalInstance',
        'scopeOriginale',
        'uuidNota'
        , function ($scope, $http, $uibModalInstance, scopeOriginale, uuidNota) {

            $scope.uuidNota = uuidNota;
            $scope.cancel = function () {
                $uibModalInstance.dismiss('cancel');
            }

            $scope.nota = '';

            $scope.getNota = function () {
                $scope.loading = true;
                if ($scope.uuidNota.uuid === 'undefined') {
                    $scope.loading = false;
                } else {
                    $http.get('/note/' + $scope.uuidNota.uuid).
                        success(function (data, status, headers, config) {
                            $scope.nota = data;
                            $scope.loading = false;
                        });
                }

            }


            $scope.salvaNota = function () {

                $scope.loading = true;
                $http.post(window.location.pathname + '/note', {

                    testo: $scope.nota.testo,

                }).success(function (data, status, headers, config) {
                    $scope.loading = true;
                    scopeOriginale.$emit('notaAggiunta');
                    $scope.cancel();
                });
            }

            $scope.aggiornaNota = function () {

                $scope.loading = true;

                $http.post('/note/' + $scope.uuidNota.uuid, {
                    _method: 'PUT',
                    testo: $scope.nota.testo

                }).success(function (data, status, headers, config) {
                    $scope.loading = true;
                    scopeOriginale.$emit('notaAggiunta');
                    $scope.cancel();
                });
            }

            $scope.cancellaNota = function () {

                $scope.loading = true;

                $http.post('/note/' + $scope.uuidNota.uuid, {
                    _method: 'DELETE',
                    testo: $scope.nota.testo

                }).success(function (data, status, headers, config) {
                    $scope.loading = true;
                    scopeOriginale.$emit('notaAggiunta');
                    $scope.cancel();
                });
            }

            $scope.getNota();
        }]);
